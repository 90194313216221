@import url('https://fonts.googleapis.com/css?family=Poppins:600|Roboto:400&display=swap');




/*     TEXT     */
html {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: calc(0.5em + 0.7vw);
  background-color: #F7F7F7; 
}

body {
  margin: 0;
  color: rgba(0, 0, 0, 1);
  background-color: #F7F7F7;
  /*
  -moz-box-shadow: inset 0 0 200px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset 0 0 200px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 200px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  */
}

h1 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 2.75em;
  line-height: 1.25em;
  margin: 0;
}

h2 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 2em;
  line-height: 1.3em;
  margin: 0;
}

h3 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 1em;
  line-height: 1.2em;
  letter-spacing: 3px;
  text-transform: uppercase;
  margin: 0;
}

h4 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 1.2em;
  line-height: 1.5em;
  margin: 0;
}

h5 {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 1em;
  line-height: 1.5em;
  margin: 0;
}

h6 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 0.8em;
  line-height: 1.4em;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin: 0;
}

p {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 1.2em;
  line-height: 1.5em;
  margin: 0;
}

a {
  color: #9F9F9F;
  text-decoration: none;
  cursor: pointer;
}

a:hover {
  color: #DFDFDF;
  text-decoration: none;
  cursor: pointer;
}

.button {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 1.1em;
  color: #000000;
  background-color: #DFDFDF;
  padding: 1em 2em 1em 2em;
  margin-top: 6em;
  border: none;
  cursor: pointer;
}

.button:hover {
  color: #DFDFDF;
  background-color: #000000;
}





/*     NAVBAR     */
#nav {
  position: fixed;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 0.8em;
  line-height: 2.5em;
  letter-spacing: 3px;
  text-align: center;
  list-style: none;
  margin: 0;
  padding: 0;
  z-index: 100;
}

#nav li a {
  text-decoration: none;
  color: rgba(0, 0, 0, 1);
  cursor: pointer;
}

#nav li a:hover {
  color: rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

#nav-top {
  height: 2.5em;
  width: 100vw;
  position: fixed;
  top: 0;
  z-index: 100;
  background-color: rgba(255, 255, 255, 1);
}

#nav-right {
  height: 2.5em;
  width: 100vh;
  position: fixed;
  transform: rotate(90deg);
  transform-origin: left top;
  left: 100%;
  z-index: 100;
  background-color: rgba(255, 255, 255, 1);
}

#nav-bottom {
  height: 2.5em;
  width: 100vw;
  position: fixed;
  bottom: 0;
  z-index: 100;
  background-color: rgba(255, 255, 255, 1);
}

#nav-left {
  height: 2.5em;
  width: 100vh;
  position: fixed;
  transform: rotate(-90deg);
  transform-origin: right top;
  right: 100%;
  z-index: 100;
  background-color: rgba(255, 255, 255, 1);
}







/*     FOOTER     */
.footer-next {
  padding: 1em 4em 3.5em 4em;
  text-align: center;
  background-color: #EDEDED;
}

#footer-dark {
  padding: 1em 4em 3.5em 4em;
  background-color: rgba(0, 0, 0, 0.8);
  box-sizing: border-box;
  z-index: 1;
  font-family: "Poppins", sans-serif;
  font-size: 0.8em;
  line-height: 2.6em;
}

#footer-light {
  padding: 1em 4em 3.5em 4em;
  background-color: #EDEDED;
  box-sizing: border-box;
  z-index: 1;
  font-family: "Poppins", sans-serif;
  font-size: 0.8em;
  line-height: 2.6em;
}

.footer-column-left {
}

.footer-column-right {
  margin-left: auto;
}

@media only screen and (max-width: 768px) {
  #footer-dark, #footer-light {
  padding: 1em 4em 4em 4em;
  }
  .footer-column-right {
    margin-left: 0;
  }
}








/*     INTRO GRID NAV     */
#intro {
  padding: 4em 6em 3em 6em;
  box-sizing: border-box;
  z-index: 1;
}

  /* Small Devices, Tablets */
@media only screen and (max-width: 768px) {
  #intro {
    padding: 2.5em 2.5em 2.5em 2.5em;
  }
}

#grid-nav {
  padding: 0em 6em 6em 6em;
  box-sizing: border-box;
  z-index: 1;
}

  /* Small Devices, Tablets */
@media only screen and (max-width: 768px) {
  #grid-nav {
    padding: 0em 2.5em 3.5em 2.5em;
  }
}

/*
ul.tabs {
  padding: 0;
}

ul.tabs li {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 0.8em;
  line-height: 1em;
  letter-spacing: 3px;
  list-style: none;
  text-transform: uppercase;
  background-color: rgba(0, 0, 0, 0.025);

}

ul.tabs li a {
  text-decoration: none;
  padding: 0.75em;
  cursor: pointer;
}

ul.tabs li a.active {
  color: rgba(255, 255, 255, 1);
  background-color: rgba(0, 0, 0, 0.9);
  cursor: default;
}
*/

ul.tabs {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

ul.tabs li {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 2em;
  line-height: 1.3em;
  margin: 0;
}

ul.tabs li a {
  text-decoration: none;
  padding-right: 0.75em;
  cursor: pointer;
}

ul.tabs li a.active {
  color: rgba(0, 0, 0, 0.9);
  cursor: default;
}

.discipline {
  background-color: #DFDFDF;
  padding: 0.25em 0.5em 0.25em 0.5em;
}

.description {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 1em;
  line-height: 1.5em;
  margin: 0;
}

.plain-link {
  color: inherit;
}





/*     PROJECT GRID     */

.fade-enter {
  opacity: 0;
  animation-fill-mode: forwards;
}

.fade-enter.fade-enter-active {
  animation-fill-mode: forwards;
  opacity: 1;
  transition: opacity 600ms ease-in;
}

.fade-exit {
  opacity: 1;
  animation-fill-mode: forwards;
}

.fade-exit.fade-exit-active {
  animation-fill-mode: forwards;
  opacity: 0;
  transition: opacity 600ms ease-in;
}

.grid-container {
  margin: 0 2em 0 2em;
  -webkit-transition: all .5s;
  -moz-transition: all .5s;
  -o-transition: all .5s;
  transition: all .5s;
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  margin: 0.5em;
}

  /* Small Devices, Tablets */
@media only screen and (min-width: 768px) {
  .column {
    flex: 1;
  }
}

.thumbnail {
    overflow: hidden;
}

.column:hover .colouraccessibility-thumbnail,
.column:focus .colouraccessibility-thumbnail,
.column:hover .aeropress-thumbnail,
.column:focus .aeropress-thumbnail,
.column:hover .sliderquiz-thumbnail,
.column:focus .sliderquiz-thumbnail,
.column:hover .houserules-thumbnail,
.column:focus .houserules-thumbnail,
.column:hover .hotelprices-thumbnail,
.column:focus .hotelprices-thumbnail,
.column:hover .perfectweekend-thumbnail,
.column:focus .perfectweekend-thumbnail,
.column:hover .actiontime-thumbnail,
.column:focus .actiontime-thumbnail,
.column:hover .workmode-thumbnail,
.column:focus .workmode-thumbnail,
.column:hover .mariah-thumbnail,
.column:focus .mariah-thumbnail,
.column:hover .climate-thumbnail,
.column:focus .climate-thumbnail,
.column:hover .coinkiller-thumbnail,
.column:focus .coinkiller-thumbnail,
.column:hover .sonypictures-thumbnail,
.column:focus .sonypictures-thumbnail,
.column:hover .spotify-thumbnail,
.column:focus .spotify-thumbnail,
.column:hover .bigtalk-thumbnail,
.column:focus .bigtalk-thumbnail,
.column:hover .toiletprojection-thumbnail,
.column:focus .toiletprojection-thumbnail {
  transform: scale(1.05);
  /*opacity: 0.5;*/
}

.column:hover .hotelprices-thumbnail,
.column:focus .hotelprices-thumbnail {
  background-image: url("./img/hotelprices_thumbnail.gif");
}

.column:hover .perfectweekend-thumbnail,
.column:focus .perfectweekend-thumbnail {
  background-image: url("./img/perfectweekend_thumbnail.gif");
}

.column:hover .sliderquiz-thumbnail,
.column:focus .sliderquiz-thumbnail {
  background-image: url("./img/sliderquiz_thumbnail.gif");
}

.column:hover .actiontime-thumbnail,
.column:focus .actiontime-thumbnail {
  background-image: url("./img/actiontime_thumbnail.gif");
}

.column:hover .bigtalk-thumbnail,
.column:focus .bigtalk-thumbnail {
  background-image: url("./img/bigtalk_thumbnail_.gif");
}

.column:hover .houserules-thumbnail,
.column:focus .houserules-thumbnail {
  background-image: url("./img/houserules_thumbnail.gif");
}

.column:hover .climate-thumbnail,
.column:focus .climate-thumbnail {
  background-image: url("./img/climate_thumbnail.gif");
}

.column:hover .spotify-thumbnail,
.column:focus .spotify-thumbnail {
  background-image: url("./img/spotify_thumbnail.jpg");
}

.column:hover .aeropress-thumbnail,
.column:focus .aeropress-thumbnail {
  background-image: url("./img/aeropress_thumbnail.gif");
}

.column:hover .coinkiller-thumbnail,
.column:focus .coinkiller-thumbnail {
  background-image: url("./img/coinkiller_thumbnail.gif");
}

.column:hover .colouraccessibility-thumbnail,
.column:focus .colouraccessibility-thumbnail {
  background-image: url("./img/colouraccessibility_thumbnail.gif");
}

.column:hover .sonypictures-thumbnail,
.column:focus .sonypictures-thumbnail {
  background-image: url("./img/sony_thumbnail.gif");
}

.column:hover .toiletprojection-thumbnail,
.column:focus .toiletprojection-thumbnail {
  background-image: url("./img/toiletprojection_thumbnail.gif");
}

.column:hover .workmode-thumbnail,
.column:focus .workmode-thumbnail {
  background-image: url("./img/workmode_thumbnail.gif");
}

.column:hover .mariah-thumbnail,
.column:focus .mariah-thumbnail {
  background-image: url("./img/mariah_thumbnail.gif");
}

.colouraccessibility-thumbnail:after,
.aeropress-thumbnail:after,
.sliderquiz-thumbnail:after,
.houserules-thumbnail:after,
.hotelprices-thumbnail:after,
.perfectweekend-thumbnail:after,
.actiontime-thumbnail:after,
.workmode-thumbnail:after,
.mariah-thumbnail:after,
.climate-thumbnail:after,
.coinkiller-thumbnail:after,
.sonypictures-thumbnail:after,
.spotify-thumbnail:after,
.bigtalk-thumbnail:after,
.toiletprojection-thumbnail:after {
  content: '';
  display: block;
  padding-bottom: 100%;
}

.colouraccessibility-thumbnail {
  background-image: url("./img/colouraccessibility_thumbnail_static.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-transition: all .5s;
  -moz-transition: all .5s;
  -o-transition: all .5s;
  transition: all .5s;
  transform: scale(1.1);
}

.aeropress-thumbnail {
  background-image: url("./img/aeropress_thumbnail_static.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-transition: all .5s;
  -moz-transition: all .5s;
  -o-transition: all .5s;
  transition: all .5s;
  transform: scale(1.1);
}

.sliderquiz-thumbnail {
  background-image: url("./img/sliderquiz_thumbnail_static.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-transition: all .5s;
  -moz-transition: all .5s;
  -o-transition: all .5s;
  transition: all .5s;
  transform: scale(1.1);
}

.houserules-thumbnail {
  background-image: url("./img/houserules_thumbnail_static.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-transition: all .5s;
  -moz-transition: all .5s;
  -o-transition: all .5s;
  transition: all .5s;
  transform: scale(1.1);
}

.hotelprices-thumbnail {
  background-image: url("./img/hotelprices_thumbnail_static.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-transition: all .5s;
  -moz-transition: all .5s;
  -o-transition: all .5s;
  transition: all .5s;
  transform: scale(1.1);
}

.perfectweekend-thumbnail {
  background-image: url("./img/perfectweekend_thumbnail_static.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-transition: all .5s;
  -moz-transition: all .5s;
  -o-transition: all .5s;
  transition: all .5s;
  transform: scale(1.1);
}

.actiontime-thumbnail {
  background-image: url("./img/actiontime_thumbnail_static.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-transition: all .5s;
  -moz-transition: all .5s;
  -o-transition: all .5s;
  transition: all .5s;
  transform: scale(1.1);
}

.workmode-thumbnail {
  background-image: url("./img/workmode_thumbnail_static.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-transition: all .5s;
  -moz-transition: all .5s;
  -o-transition: all .5s;
  transition: all .5s;
  transform: scale(1.1);
}

.mariah-thumbnail {
  background-image: url("./img/mariah_thumbnail_static.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-transition: all .5s;
  -moz-transition: all .5s;
  -o-transition: all .5s;
  transition: all .5s;
  transform: scale(1.1);
}

.climate-thumbnail {
  background-image: url("./img/climate_thumbnail_static.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-transition: all .5s;
  -moz-transition: all .5s;
  -o-transition: all .5s;
  transition: all .5s;
  transform: scale(1.1);
}

.coinkiller-thumbnail {
  background-image: url("./img/coinkiller_thumbnail_static.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-transition: all .5s;
  -moz-transition: all .5s;
  -o-transition: all .5s;
  transition: all .5s;
  transform: scale(1.1);
}

.sonypictures-thumbnail {
  background-image: url("./img/sony_thumbnail_static.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-transition: all .5s;
  -moz-transition: all .5s;
  -o-transition: all .5s;
  transition: all .5s;
  transform: scale(1.1);
}

.bigtalk-thumbnail {
  background-image: url("./img/bigtalk_thumbnail_static_.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-transition: all .5s;
  -moz-transition: all .5s;
  -o-transition: all .5s;
  transition: all .5s;
  transform: scale(1.1);
}

.spotify-thumbnail {
  background-image: url("./img/spotify_thumbnail_static.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-transition: all .5s;
  -moz-transition: all .5s;
  -o-transition: all .5s;
  transition: all .5s;
  transform: scale(1.1);
}

.toiletprojection-thumbnail {
  background-image: url("./img/toiletprojection_thumbnail_static.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-transition: all .5s;
  -moz-transition: all .5s;
  -o-transition: all .5s;
  transition: all .5s;
  transform: scale(1.1);
}






/*     PROJECTS     */
#title {
  background-color: #DFDFDF;
  padding-top: 2.5em;
  padding-left: 3.5em;
  height: 2.5em;
  font-family: "Poppins", sans-serif;
  font-size: 0.8em;
  line-height: 2.6em;
  z-index: 0;
}

.scrollbar {
  position: relative;   
  padding: 0;   
  margin: 0;
}

#project-page {
  /*min-height: 100vh;*/
  padding: 4em 6em 6em 6em;
  box-sizing: border-box;
  z-index: 1;
}

  /* Small Devices, Tablets */
@media only screen and (max-width: 768px) {
  #project-page {
    padding: 2.5em 2.5em 2.5em 2.5em;
  }
}

.project-highlight {
  padding: 4em 0 4em 0;
}

.highlight-caption-third {
  width: 33%;
  display: inline-block;
  vertical-align: top;
  padding: 0 0 0 8.5em;
  box-sizing: border-box;
}

.highlight-caption-half {
  width: 50%;
  padding-left: 16%;
  display: inline-block;
  vertical-align: top;
  padding: 0 0 0 8.5em;
  box-sizing: border-box;
}

.highlight-image-two-thirds {
  width: 66%;
  display: inline-block;
  vertical-align: top;
  padding: 0 1em 0 2.5em;
  box-sizing: border-box;
}

.highlight-image-half {
  width: 50%;
  display: inline-block;
  vertical-align: top;
  padding: 0 0 0 2.5em;
  box-sizing: border-box;
  margin-left: 5em;
}

  /* Small Devices, Tablets */
@media only screen and (max-width: 768px) {
  .project-highlight {
    padding: 2.5em 0 2.5em 0;
  }

  .highlight-caption-third, .highlight-caption-half {
    width: 100%;
    padding: 0 5em 0 5em;
  }

  .highlight-image-two-thirds {
    width: 100%;
    padding: 0 1.5em 0 5em;
  }

  .highlight-image-half {
    width: 100%;
    padding: 0 5em 0 5em;
    margin: 0;
  }
}

.project-title {
  position: fixed;  
  width: 100%;
}

.project-text-fourth {
  width: 20%;
  display: inline-block;
  vertical-align: top;
  padding: 2.5em;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
}

.project-text-two-thirds {
  width: 66%;
  display: inline-block;
  vertical-align: top;
  padding: 0 2.5em 0 2.5em;
  box-sizing: border-box;
}

.project-caption-third {
  width: 33%;
  display: inline-block;
  vertical-align: top;
  padding-right: 2.5em;
  box-sizing: border-box;
}

.project-caption-half {
  width: 50%;
  display: inline-block;
  vertical-align: top;
  padding-right: 2.5em;
  box-sizing: border-box;
}

.project-caption {
  width: 100%;
}

.project-text-half {
  width: 50%;
  display: inline-block;
  vertical-align: top;
  padding: 0 2.5em 0 2.5em;
  box-sizing: border-box;
}

  /* Small Devices, Tablets */
@media only screen and (max-width: 768px) {
  .project-text-fourth {
    display: block;
    width: 40%;
  }

  .project-text-half {
    width: 100%;
  }

  .project-caption-third {
    width: 100%;
  }

  .project-text-two-thirds {
    width: 100%;
  }
}

.project-text {
  width: 80%;
  padding: 0 2.5em 0 2.5em;
}

img {
  padding: 0 2.5em 0 2.5em;
  width: 100%;
  height: auto;
  box-sizing: border-box;
}

video {
  padding: 0 2.5em 0 2.5em;
  width: 100%;
  height: auto;
  box-sizing: border-box;
}

video:focus {
  outline: none;
}

.padding-top-05em {
  padding-top: 0.5em;
}

.padding-top-1em {
  padding-top: 1em;
}

.padding-top-2em {
  padding-top: 2em;
}

.padding-top-3em {
  padding-top: 3em;
}





/*     MODAL     */
.modal {
  position: absolute;
  left: 5%;
  width: 90%;
  padding-bottom: 1em;
  margin-bottom: 4em;
  box-sizing: border-box;
  background: #F7F7F7;
  box-shadow: 0 0 1em rgba(0, 0, 0, 0.1);
  filter: blur(0);
  transform: scale(1);
  opacity: 1;
  visibility: visible;
  z-index: 1000;
}

#backdrop {
  background-color: rgba(255, 255, 255, 0.6);
  z-index: 500;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 1.3s;
  display: none;
}

.close {
  position: absolute;
  right: 1.5em;
  top: 1.5em;
  width: 1.5em;
  height: 1.5em;
  opacity: 1;
}
.close:hover {
  opacity: 0.3;
}
.close:before, .close:after {
  position: absolute;
  left: 1em;
  content: ' ';
  height: 1em;
  width: 2px;
  background-color: #333;
}
.close:before {
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
}

.close-project {
  position: absolute;
  right: 1.5em;
  top: 2.5em;
  width: 3em;
  height: 1.5em;
  opacity: 1;
}
.close-project:hover {
  opacity: 0.3;
}
.close-project:before, .close-project:after {
  position: absolute;
  left: 1.2em;
  content: ' ';
  height: 1em;
  width: 2px;
  background-color: #000000;
}
.close-project:before {
  transform: rotate(45deg);
}
.close-project:after {
  transform: rotate(-45deg);
}







/*     PROCESS GRID     */
.design-process {
  background-color: rgba(0, 0, 0, 0.9);
}

.process-container {
  margin: 0 2.5em 0 2.5em;
  display: flex;
  flex-wrap: nowrap; 
  overflow: auto;
}

.process-flex {
  width: 987.14px;
}

.process-row {
  display: flex;
}
/*
.process-stage {
  background: aqua;
  width: 434px;
}
*/
.process-icon {
  width: 84.29px;
  height: 123px;
  opacity: 0.3;
  cursor: pointer;
}

.process-icon:hover {
  opacity: 1!important;
}

.icon {
  width: 84.29px;
  height: 84.29px;
  padding: 0;
}

.small-icon {
  max-width: 84.29px;
  max-height: 84.29px;
  padding: 0;
  margin: 0 auto;
}

.spacer-118px {
  width: 84.29px;
  height: 123px;
}

.spacer-80px {
  width: 25px;
}

.white {
  color: #FFFFFF;
}

/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip .tooltip-text {
  visibility: hidden;
  min-width: 10em;
  max-width: 14em;
  background-color: #494949;
  color: #FFFFFF;
  padding: 1em;

  /* Position the tooltip text */
  position: absolute;
  left: 80%;
  top: -50%;
  z-index: 1;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

.process-container::-webkit-scrollbar {
  width: 0px;
}

.process-container {
  scrollbar-width: thin;
  scrollbar-color: #FFFFFF inherit;
}

.process-container::-webkit-scrollbar-track {
  background: inherit;
}

.process-container::-webkit-scrollbar-thumb {
  background-color: inherit;
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 1);
}







/*     BLOG     */
.blog-list-image {
  padding: 0;
  width: 25%;
  vertical-align: top;
}

.blog-list {
  width: 75%;
  padding: 0 2.5em 0 2.5em;
  display: inline-block;
  vertical-align: middle;
  box-sizing: border-box;
}






/*     ABOUT     */
.photo {
  background-image: url("./img/rebecca.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: inline-block;
  vertical-align: bottom;
}

.current-album {
  background-image: url("./img/album.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 4em;
  height: 4em;
  display: inline-block;
  vertical-align: bottom;
}

.current-book {
  background-image: url("./img/book.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 4em;
  height: 4em;
  display: inline-block;
  vertical-align: bottom;
}






